<template>
  <div class="content">
    <div class="body">
      <h2 class="title">Exemple paragraphe</h2>
      <h4>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua.
      </h4>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
        diam. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
        amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
        nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
        rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
        ipsum dolor sit amet.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren, no sea takimata sanctus est Lorem
      </p>

      <h3 id="info">Exemple information</h3>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
        voluptua.
      </p>
      <div class="bulle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
        >
          <g
            id="Icon_feather-info"
            data-name="Icon feather-info"
            transform="translate(-1.5 -1.5)"
          >
            <path
              id="Tracé_78064"
              data-name="Tracé 78064"
              d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z"
              fill="none"
              stroke="#4d569b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              id="Tracé_78065"
              data-name="Tracé 78065"
              d="M18,24V18"
              fill="none"
              stroke="#4d569b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              id="Tracé_78066"
              data-name="Tracé 78066"
              d="M18,12h0"
              fill="none"
              stroke="#4d569b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
          </g>
        </svg>

        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
        diam. At vero eos et accusam et justo duo dolores et ea rebum.
      </div>

      <h3 id="image">Exemple image</h3>
      <h4>Lorem ipsum dolor sit amet, consetetur sadipscing elitr</h4>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
        diam. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren,
      </p>
      <div class="double-img">
        <img src="@/assets/web1.png" />
        <img src="@/assets/web2.png" />
      </div>

      <h3 id="image">Exemple video</h3>
      <h4>Lorem ipsum dolor sit amet, consetetur sadipscing elitr</h4>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
        diam. At vero eos et accusam et justo duo dolores et ea rebum. Stet
        clita kasd gubergren,
      </p>
      <div ref="video" class="video">
        <video
          preload="metadata"
          :src="
            downloadUrl +
            'public/how-it-works/videos/11682596642_Suppression  un article.mp4'
          "
          controls
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters([""]),
  },

  methods: {
    ...mapActions([]),

    playPause() {
      var parent = this.$refs["video"];
      var myVideo = parent.children[0];

      if (myVideo.paused) {
        parent.className = "hideVideo";
        myVideo.play();
        myVideo.controls = true;
      }
    },
  },

  watch: {
    "$route.hash": {
      handler(newHash) {
        if (newHash) {
          this.$nextTick(() => {
            const video = document.getElementById(newHash.slice(1));
            if (video) {
              window.scrollTo({
                top: video.offsetTop,
                behavior: "smooth",
              });
            }
          });
        }
      },
      immediate: true,
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.content {
  flex: 1;

  .body {
    @media only screen and (max-width: 1024px) {
      width: 97%;
      padding: 15px;
    }
    @media only screen and (max-width: 724px) {
      width: 100%;
    }
    width: 95%;
    margin: auto;
    padding: 24px 32px 48px;
  }

  .title {
    color: #242424;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    color: #474747;
    margin-bottom: 18px;
    line-height: 1.5;
  }

  h3 {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  p {
    font-size: 12px;
    color: #474747;
    font-weight: 300;
    line-height: 2;
  }

  .bulle {
    @media only screen and (max-width: 900px) {
      padding: 16px;
      font-size: 12px;
    }
    border-radius: 25px;
    margin-bottom: 24px;
    border: 1px solid #dee2ff;
    background-color: #dee2ff;
    padding: 24px;
    display: flex;
    gap: 12px;
    align-items: center;
    color: #4d569b;
    font-size: 14px;
    line-height: 1.5;

    svg {
      @media only screen and (max-width: 900px) {
        display: none;
      }
      width: 48px;
      height: 48px;
    }
  }

  .double-img {
    @media only screen and (max-width: 900px) {
      display: block;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    img {
      @media only screen and (max-width: 900px) {
        width: 100%;
        margin-bottom: 12px;
      }
      width: 48%;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
  .video {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    position: relative;
    width: 80%;
    margin: 0 auto 24px;

    .play {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($color: #000, $alpha: 0.4);
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #373737;
        width: 84px;
        height: 84px;
        border-radius: 50%;
      }
    }
  }
}

.showVideo {
  .play {
    display: flex;
  }

  .pause {
    display: none !important;
  }
}

.hideVideo {
  position: relative;
  width: 80%;
  margin: 0 auto 24px;
  .play {
    display: none;
  }

  &:hover .pause {
    display: flex;
  }
}
</style>
